:root {
  /* theme colors */

  --color-primary: #0052cc;
  --color-primary-rgb: 0,82,204;
  --color-primary-contrast: #ffffff;
  --color-primary-contrast-rgb: 255,255,255;
  --color-primary-shade: #0048b4;
  --color-primary-tint: #1a63d1;

  --color-secondary: #3dc2ff;
  --color-secondary-rgb: 61,194,255;
  --color-secondary-contrast: #ffffff;
  --color-secondary-contrast-rgb: 255,255,255;
  --color-secondary-shade: #36abe0;
  --color-secondary-tint: #50c8ff;

  --color-tertiary: #5260ff;
  --color-tertiary-rgb: 82,96,255;
  --color-tertiary-contrast: #ffffff;
  --color-tertiary-contrast-rgb: 255,255,255;
  --color-tertiary-shade: #4854e0;
  --color-tertiary-tint: #6370ff;

  --color-success: #2dd36f;
  --color-success-rgb: 45,211,111;
  --color-success-contrast: #ffffff;
  --color-success-contrast-rgb: 255,255,255;
  --color-success-shade: #28ba62;
  --color-success-tint: #42d77d;

  --color-warning: #ffc409;
  --color-warning-rgb: 255,196,9;
  --color-warning-contrast: #000000;
  --color-warning-contrast-rgb: 0,0,0;
  --color-warning-shade: #e0ac08;
  --color-warning-tint: #ffca22;

  --color-danger: #eb445a;
  --color-danger-rgb: 235,68,90;
  --color-danger-contrast: #ffffff;
  --color-danger-contrast-rgb: 255,255,255;
  --color-danger-shade: #cf3c4f;
  --color-danger-tint: #ed576b;

  --color-dark: #222428;
  --color-dark-rgb: 34,36,40;
  --color-dark-contrast: #ffffff;
  --color-dark-contrast-rgb: 255,255,255;
  --color-dark-shade: #1e2023;
  --color-dark-tint: #383a3e;

  --color-medium: #92949c;
  --color-medium-rgb: 146,148,156;
  --color-medium-contrast: #ffffff;
  --color-medium-contrast-rgb: 255,255,255;
  --color-medium-shade: #808289;
  --color-medium-tint: #9d9fa6;

  --color-light: #f4f5f8;
  --color-light-rgb: 244,245,248;
  --color-light-contrast: #000000;
  --color-light-contrast-rgb: 0,0,0;
  --color-light-shade: #d7d8da;
  --color-light-tint: #f5f6f9;

  /* fonts */
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  /* shadows */
  --box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0,0,0,0.24);
  --box-shadow-1: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  --box-shadow-2: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  --box-shadow-3: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  --box-shadow-4: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  --box-shadow-5: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);

  /* backgrounds */
  --bg-overlay: rgba(0, 0, 0, .7);
  --bg-overlay-tint: rgba(200, 200, 200, .85);
  --bg-editable-text-field: #ebecf0;
  --bg-mobile-nav: #c8c8c8;
  --bg-avatar: #f7eef2;
  --bg-avatar-hover: #d0d4f3;
  --bg-dropdown-li: #d1cfcf;
  --bg-main: #843659;
  --bg-secondary: #eeacca;

  /* border radius */
  --brad-btn: 2px;
  --brad-card: 5px;
  --brad-round: 25px;
  /* text */
  --text-primary: rgba(201, 180, 180, 0.87);
  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-hint: rgba(0, 0, 0, 0.38);
  --text-white: #fff;
  --text-light: rgba(255, 255, 255, 0.7);
  --text-hint-light: rgba(255, 255, 255, 0.5);
  /* btn */
  --btn-disabled: rgb(206, 203, 203);
  /**/
  --btn-primary-padding: 10px;
  --btn-primary-font-size: 16px;
  --btn-primary-font-weight: 600;

  --input-text-bg-color-prestine: #f6f8fa;
  --input-text-bg-color-focus: #fff;
  --input-text-border-color-focus: #4c9aff;
  --input-text-border-color-prestine: #fafbfc;
  /* breakpoints */
  --breakpoint-mobile: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chevron {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 2px;
}
.chevron.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.chevron.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.chevron.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.chevron.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.collapse .chevron {
  transition: all 0.5s ease;
}
.collapse .active .up,
.collapse a.active + .up,
.collapse:focus-within .up {
  transform:  rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.collapse .active .down,
.collapse a.active + .down {
  transform:  rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

@media (max-width: 768px) {
  .no-scroll {
    overflow: hidden;
  }
}
.carousel-animate-delay {
  animation-delay: 6s;
}
.loading-inside-123 {
  overflow: hidden;
  max-height: 100vh;
}
